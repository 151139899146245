<template>
    <div class="mp-investment-case">
        <div class="container main-page-container">
            <div class="row">
                <!-- <div class="col-default-4 col-tablet-6 col-v-tablet-12">
                    <div class="u-bottom-padding--s">
                        <z-caption class="u-bottom-margin--s" tag="h2" size="5xl">
                            Investment case
                        </z-caption>
                        <p class="mp-investment-case__text">
                            QIWI Global operates on&nbsp;specialized high growth markets strongly affected by&nbsp;favorable secular tailwinds. Our business model provides with exceptional operating efficiency and low capex requirements.
                        </p>
                        <z-button tag="a" href="/investors/investment-case/" size="m">Learn more</z-button>
                    </div>
                </div> -->
                <div class="col-default-7 col-default-offset-0 col-tablet-6 col-tablet-offset-0 col-v-tablet-12">
                    <div class="mp-investment-case__circle-block">
                        <reports-and-result class="mp-investment-case__reports-and-result" />
                        <div class="mp-investment-case__sustainability" v-if="data.presentation && data.presentation.link">
                            <z-caption class="mp-investment-case__sustainability-title" tag="p" size="4xl" weight="700">
                                Sustainability
                            </z-caption>
                            <z-list class="mp-investment-case__sustainability-description" theme="excellence" size="s">
                                <z-list-item>Responsible technologies</z-list-item>
                                <z-list-item>Employees and local communities</z-list-item>
                                <z-list-item>Financial inclusion</z-list-item>
                            </z-list>
                            <div class="mp-investment-case__sustainability-file file">
                                <span class="file__title">ESG Report</span>
                                <z-link
                                    class="file__link"
                                    :href="data.presentation.link"
                                    icon="file"
                                    theme="gray"
                                    target="_blank"
                                >pdf</z-link>
                            </div>
                            <div class="u-top-margin--2xs">
                                <z-link
                                    size="s"
                                    href="/sustainability/"
                                >Learn more</z-link>
                            </div>
                        </div>
                        <calendar
                            :class="[
                                'mp-investment-case__calendar',
                                {'mp-investment-case__calendar--full-width': !data.presentation }
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportsAndResult from './ReportsAndResult.vue'
import Calendar from './Calendar.vue'

export default {
    name: 'mp-investment-case',
    components: {
        ReportsAndResult,
        Calendar
    },
    data () {
        return {
            data: this.$root.app.components.sustainability
        }
    }
}
</script>

<style lang="scss">
.mp-investment-case {
    @include padding-level(top, 3XL);
    @include padding-level(bottom, 3XL);
    background-image: url(/images/main-page/investment-case.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    @include breakpoint (v-tablet) {
        background-image: url(/images/main-page/investment-case--mobile.jpg);
        padding-bottom: 200px;
        background-position: 50% 100%;
        background-size: 100% auto;
        background-color: #b9dcf8;
    }

    @include breakpoint (mobile) {
        background-size: auto 300px;
    }

    &__text {
        @include typo-level(3XL);
        font-weight: 400 !important;
        line-height: 1.45 !important;
    }

    &__reports-and-result,
    &__calendar {
        position: relative;
        overflow: hidden;

        > * {
            position: relative;
            z-index: 2;
        }

        &:after {
            content: '';
            display: block;
            width: 22vw;
            height: 22vw;
            min-width: 400px;
            min-height: 400px;
            max-width: 450px;
            max-height: 450px;
            padding-top: 100%;
            background-image: url(/images/circle.svg);
            background-repeat: no-repeat;
            position: absolute;
            left: 100%;
            background-size: contain;

            @include breakpoint (tablet) {
                display: none;
            }
        }
    }

    &__circle-block {
        display: flex;
        flex-wrap: wrap;
    }

    &__reports-and-result {
        &:after {
            bottom: 0;
            transform: translateX(-65%) translateY(calc(92% + 4px));
        }
    }

    &__calendar {
        flex-basis: 66.66667%;
        max-width: 66.66667%;

        &--full-width {
            flex-basis: 100%;
            max-width: 100%;

            &:after {
                transform: translateX(-63%) translateY(-9%) !important;
            }
        }
    }

    &__sustainability {
        flex-basis: calc(33.3333% - 4px);
        max-width: calc(33.3333% - 4px);
        background-color: $token-colors-white;
        border-radius: 10px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(right, S);
        @include padding-level(left, S);
        margin-right: 4px;
        display: flex;
        flex-direction: column;

        &-title {
            margin-bottom: 20px !important;
        }

        &-description {
            margin-bottom: $token-spacers-xs !important;

            .z-list-item + .z-list-item {
                margin-top: 12px !important;
            }
        }

        &-file {
            padding-left: 18px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                background: $token-colors-primary;
                border-radius: 50%;
                left: 0;
                top: 0.65em;
            }

            .file__title {
                font-weight: 600;
                font-size: 16px;
                line-height: 1.2;
                margin-right: 20px;
            }

            .file__link {
                font-weight: 400;
                font-size: 14px;
                color: $token-colors-gray-60;
            }
        }

        @include breakpoint (tablet) {
            margin-right: 0;
        }
    }

    &__calendar,
    &__sustainability {
        margin-top: 4px;

        &:after {
            top: 0;
            transform: translateX(-65%) translateY(-12%);
        }

        @include breakpoint (tablet) {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}
</style>
